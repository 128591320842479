import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, Upload, Image, Switch } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {
  createDetection,
  createShelfObjectDetection,
  getDetectionById,
  downloadMetadata,
  createShelf,
  createImage,
} from '../api';
import ShelvesDetectionsTable from '../components/ShelvesDetectionsTable';


const DetectionComponent = ({ detection, metadata }) => {
  return (
    <Card title="Information" bordered={false} style={{ width: 300 }}>
      <p>
        <strong>Detection ID:</strong> {detection?.id}
      </p>
      <p>
        <strong>Process ID:</strong> {detection?.process_id}
      </p>
      <p>
        <strong>Created At:</strong> {detection?.created_at}
      </p>
      <p>
        <strong>Object Detected:</strong> {metadata?.detection?.boxes?.length}
      </p>
    </Card>
  );
};

const ShelfPage = () => {
  const [fileList, setFileList] = useState([]);
  const [currentDetection, setCurrentDetection] = useState(null);
  const [detection, setDetection] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [runShelfDetector, setRunShelfDetector] = useState(false);

  useEffect(() => {
    const fetchDetection = async () => {
      if (currentDetection) {
        const detection = await getDetectionById(currentDetection.detectionId);
        setDetection(detection);
      }
    }

    if (currentDetection) {
      console.log('currentDetection', currentDetection);
      fetchDetection();
    }
  }, [currentDetection]);

  useEffect(() => {
    if (detection && detection.metadata_file_url) {
      console.log('detection loaded', detection);
      downloadMetadata(detection.metadata_file_url)
        .then((metadata) => {
          console.log('metadata', metadata);
          setMetadata(metadata);
        })
        .catch((error) => {
          console.error('Error downloading metadata', error);
        });
    }
  }, [detection]);

  useEffect(() => {
    if (metadata) {
      console.log('metadata loaded', metadata);
    }
  }, [metadata]);

  const handleChange = async (imgUploaded) => {
    console.log('[INFO][ShelfPage] uploading image for detection and processing', imgUploaded);
    const { fileList: newFileList } = imgUploaded;
    if (imgUploaded.file.status === 'done') {
      console.log('The server for Object Detection was completed. Response:', imgUploaded.file.response);

      const { metadata: metadataFileUrl, process_id } = imgUploaded.file.response.data;

      const medatadaContent = await downloadMetadata(metadataFileUrl);

      const { original_image, bbox_image, detection: { boxes } } = medatadaContent;

      const { imageId: originalImageId } = await createImage({
        id: original_image.id,
        url: original_image.file_path,
      })

      const { imageId: detectionImageId } = await createImage({
        id: null, // NOTE: The bbox image use the same id as the original image with the bbox suffix (request a new id)
        url: bbox_image.file_path,
      })

      const { shelfId } = await createShelf({
        imageId: originalImageId,
      });

      // TODO: call from the API
      const detectorTypes = [
        { id: '4576cec0-cf23-436b-a194-d1ba825fab10', title: 'generics' },
        { id: 'ee2db0fc-5ced-4526-bb08-cfa71307dc08', title: 'shelves' },
      ]

      const getDetectorType = (detectorTypeName) => detectorTypes.filter(({ title }) => title === detectorTypeName)[0].id;

      const { detectionId } = await createDetection({
        metadataFileUrl,
        processId: process_id,
        imageId: detectionImageId,
        detectionTypeId: getDetectorType(runShelfDetector ? 'shelves' : 'generics'),
      });

      const { shelfObjectDetectionId } = await createShelfObjectDetection(detectionId, {
        shelfId, detectionId, boxes
      });

      console.log(`
        The Object Detection was completed.
        shifId: ${shelfId}
        detectionId: ${detectionId}
        shelfObjectDetectionId: ${shelfObjectDetectionId}
      `);

      toast.success('Imagen subida correctamente');
      setCurrentDetection({
        shelfId,
        detectionId
      });
    }
    setFileList(newFileList);
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Card title="Select Detection" bordered={false}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
              <p>Shelf Object Detector: </p><Switch value={runShelfDetector} onChange={() => setRunShelfDetector(!runShelfDetector)} />
            </div>
            {/* <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', flexWrap: 'wrap' }}>
              <Image
                width={80}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              />
              <Image
                width={80}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              />
              <Image
                width={80}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              />
              <Image
                width={80}
                src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              />
            </div> */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
              <Upload
                action="https://fsanchez.cl/object-detector/upload"
                data={() => ({ shelf_detector: runShelfDetector })}
                listType="picture-card"
                fileList={fileList}
                onChange={handleChange}
              >
                <button style={{ border: 0, background: 'none' }} type="button">
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </button>
              </Upload>
            </div>
          </Card>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Card title="Result" bordered={false}>
            <Image
              width={200}
              src={metadata?.bbox_image?.file_path}
            />
          </Card>

        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DetectionComponent
            detection={detection}
            metadata={metadata}
          />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <ShelvesDetectionsTable />
      </div>
    </>
  );
};

export default ShelfPage;
