import axios from 'axios';

export const getBrandsList = async () => {
    const response = await axios.get('https://fsanchez.cl/brands');
    return response.data;
}

export const createBrand = async (brand) => {
    const response = await axios.post('https://fsanchez.cl/brands', brand);
    return response.data;
}
