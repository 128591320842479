import React, { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  Card,
  Input,
  Upload,
  AutoComplete,
} from 'antd';
import { toast } from 'react-toastify';

import { getCategoriesList, getBrandsList } from '../api';

const normFile = (e) => Array.isArray(e) ? e : e?.fileList;

const ProductForm = ({ onSubmit, onCancel, product }) => {
  const [imageId, setImageId] = useState(product && product.image ? product.image.id : null);
  const [form] = Form.useForm();

  const [categories, setCategories] = useState([]);
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [category, setCategory] = useState({ id: null, value: '' });

  const [brands, setBrands] = useState([]);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [brand, setBrand] = useState({ id: null, value: '' });

  const [title, setTitle] = useState('');
  const [size, setSize] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      console.log('Loading categories');
      const categories = await getCategoriesList();
      const categoriesValues = categories.map(category => ({ id: category.id, value: category.title }));
      setCategories(categoriesValues);
      setCategoriesOptions(categoriesValues);
    };

    const fetchBrands = async () => {
      console.log('Loading brands');
      const brands = await getBrandsList();
      const brandsValues = brands.map(brand => ({ id: brand.id, value: brand.title }));
      setBrands(brandsValues);
      setBrandsOptions(brandsValues);
    };

    fetchCategories();
    fetchBrands();
  }, []);

  useEffect(() => {
    if (product && product.id && product.image) {
      console.log('ProductForm - product', product);
      form.setFieldsValue({
        upload: [{
          uid: product.image.id,
          name: product.image.id,
          status: 'done',
          url: product.image.url,
        }],
      });
      setImageId(product.image.id);
    }
    if (product && product.id) {
      form.setFieldsValue({
        category: product.category.title,
        brand: product.brand.title,
        title: product.title,
        size: product.size,
      });
      setBrand({ id: product.brand.id, value: product.brand.title });
      setCategory({ id: product.category.id, value: product.category.title });
      setTitle(product.title);
      setSize(product.size);
    }
  }, [form, product])

  const firstLetterToUpperCase = (text) => text.charAt(0).toUpperCase() + text.slice(1);

  const onChangeCategory = (data) => {
    console.log('onChangeCategory', data);
    setCategory(firstLetterToUpperCase(data));
    form.setFieldValue('category', firstLetterToUpperCase(data));
  }

  const onChangeBrand = (data) => {
    console.log('onChangeBrand', data);
    setBrand(firstLetterToUpperCase(data));
    form.setFieldValue('brand', firstLetterToUpperCase(data));
  }

  const onSelectCategory = (data) => {
    console.log('onSelectCategory', data);
    let categoryFound = categories.find(category => category.value === data);
    if (!categoryFound) {
      categoryFound = { id: null, value: data };
    }
    console.log('onSelectCategory - categoryFound', categoryFound);
    setCategory(categoryFound);
    setCategoriesOptions(categories);
    form.setFieldsValue({ category: categoryFound.value });
  }

  const onSelectBrand = (data) => {
    console.log('onSelectBrand', data);
    let brandFound = brands.find(brand => brand.value === data);
    if (!brandFound) {
      brandFound = { id: null, value: data };
    }
    console.log('onSelectBrand - brandFound', brandFound);
    setBrand(brandFound);
    setBrandsOptions(brands);
    form.setFieldsValue({ brand: brandFound.value });
  }

  const getCategoriesValue = (text) => {
    const textToLowerCase = text.toLowerCase();
    const categoriesToLowerCase = categories.map(category => ({ value: category.value.toLowerCase(), id: category.id }));
    const categoriesFound = categoriesToLowerCase.filter(category => category.value.includes(textToLowerCase));
    setCategory(firstLetterToUpperCase(text));
    return [
      ...[{ value: firstLetterToUpperCase(text), id: null }],
      ...categoriesFound.map(category => ({ value: firstLetterToUpperCase(category.value), id: category.id }))
    ];
  }

  const getBrandsValue = (text) => {
    const textToLowerCase = text.toLowerCase();
    const brandsToLowerCase = brands.map(brand => ({ value: brand.value.toLowerCase(), id: brand.id }));
    const brandsFound = brandsToLowerCase.filter(brand => brand.value.includes(textToLowerCase));
    setBrand(firstLetterToUpperCase(text));
    return [
      ...[{ value: firstLetterToUpperCase(text), id: null }],
      ...brandsFound.map(brand => ({ value: firstLetterToUpperCase(brand.value), id: brand.id }))
    ];
  }

  return (
    <Card style={{ width: '80vw' }}>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
      >
        <Form.Item label="Category" name="category" placeholder="Categoria de la linea de producto (Bebida, Lacteo, Aceite)">
          <AutoComplete
            value={category.value}
            options={categoriesOptions}
            onSelect={onSelectCategory}
            onSearch={(text) => setCategoriesOptions(getCategoriesValue(text))}
            onChange={onChangeCategory}
            placeholder="Category"
          />
        </Form.Item>
        <Form.Item label="Brand" name="brand" placeholder="Marca de la linea de producto (Nestle, Coca Cola, P&G)">
          <AutoComplete
            value={brand.value}
            options={brandsOptions}
            onSelect={onSelectBrand}
            onSearch={(text) => setBrandsOptions(getBrandsValue(text))}
            onChange={onChangeBrand}
            placeholder="Brand"
          />
        </Form.Item>
        <Form.Item label="Title" name="title" placeholder="Titulo de la linea de producto (Light, Zero, Normal, Chocolate)">
          <Input
            value={title}
            placeholder='Title'
            onInput={e => e.target.value = firstLetterToUpperCase(e.target.value)}
            onChange={e => setTitle(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Size" name="size" placeholder="Tamaño de la linea de producto (1L, 2L, 500ml, 2kg, 1kg)">
          <Input
            value={size}
            placeholder='Size'
            onChange={e => setSize(e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Upload" valuePropName="fileList" getValueFromEvent={normFile} name="upload">
          <Upload action="https://fsanchez.cl/image/upload" listType="picture-card" onChange={imgUploaded => {
            if (imgUploaded.file.status === 'done') {
              toast.success('Image uploaded');
              const { imageId } = imgUploaded.file.response
              setImageId(imageId)
            }
            if (imgUploaded.file.status === 'error') {
              toast.error('Error uploading image');
            }
          }}>
            <button style={{ border: 0, background: 'none' }} type="button">
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Upload</div>
            </button>
          </Upload>
        </Form.Item>
        <Button type="primary" onClick={async () => {
          if (!imageId) {
            toast.error('Please upload an image');
            return;
          }
          if (!brand || !brand.value) {
            toast.error('Please select a brand');
            return;
          }
          if (!category || !category.value) {
            toast.error('Please select a category');
            return;
          }
          if (!title) {
            toast.error('Please enter a title');
            return;
          }
          if (!size) {
            toast.error('Please enter a size');
            return;
          }

          const productPayload = {
            brand: {
              id: brand.id,
              value: brand.value,
            },
            category: {
              id: category.id,
              value: category.value,
            },
            title,
            size,
            imageId,
          };

          if (product && product.id) {
            onSubmit(product.id, productPayload)
          } else {
            onSubmit(productPayload)
          }
        }}>
          Submit
        </Button>
        <Button type="default" onClick={() => {
          form.resetFields();
          onCancel();
        }}>
          Cancel
        </Button>
      </Form>
    </Card>
  );
};

export default ProductForm;
