import React, { useEffect, useState } from 'react';
import { Button, Space, List } from 'antd';
import {  toast } from 'react-toastify';

import ProductForm from '../components/ProductForm';
import ProductComponent from '../components/ProductComponent';

import { createProduct, getProductsList, deleteProduct, updateProduct } from '../api';

const ProductListPage = () => {
  const [products, setProducts] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [productIdToUpdate, setProductIdToUpdate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const productsList = await getProductsList();
      setProducts(productsList);
    }

    fetchData();
  }, []);

  const onCreateProduct = async (product) => {
    try {
      console.log('onSubmitProduct - creating product', product);

      await createProduct(product);
      const productsList = await getProductsList();

      setProducts(productsList);
      setOpenForm(false);
      toast.success('Product created');
    } catch (error) {
      console.error('onSubmitProduct', error);
      toast.error('Error creating product');
    }
  }

  const onCloseForm = () => {
    setOpenForm(false);
  }

  const onEditProduct = (productId) => async () => {
    console.log('onEditFormProduct - productId', productId);
    setProductIdToUpdate(productId);
    setIsUpdate(true);
    setOpenForm(true);
  };

  const onUpdateProduct = async (productId, product) => {
    try {
      console.log('onUpdateProduct', productId, product);
      await updateProduct(productId, product);
      const productsList = await getProductsList();
      setProducts(productsList);
      setOpenForm(false);
      toast.success('Product updated');
    } catch (error) {
      console.error('onUpdateProduct', error);
      toast.error('Error updating product');
    }
  };

  const onDeleteProduct = (productId) => async () => {
    try {
      console.log('onDeleteProduct', productId);
      await deleteProduct(productId);
      const productsList = await getProductsList();
      setProducts(productsList);
      toast.success('Product deleted');
    } catch (error) {
      console.error('onDeleteProduct', error);
      toast.error('Error deleting product');
    }
  };

  return (
    <>
      {!openForm && (
        <Space direction="vertical" size="middle" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginBottom: '1em' }}>
          <Button type="primary" onClick={() => setOpenForm(true)}>
            New product
          </Button>
        </Space>
      )}
      <Space direction="vertical" size="middle" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {openForm && (
          <ProductForm
            product={isUpdate ? products.find(product => product.id === productIdToUpdate) : null}
            onSubmit={isUpdate ? onUpdateProduct : onCreateProduct}
            onCancel={onCloseForm}
          />
        )}
        {!openForm && products && (
          <List
            grid={{ gutter: 16, xs: 1, sm: 2, md: 4, lg: 4, xl: 6, xxl: 3 }}
            dataSource={products}
            renderItem={(product) => (
              <List.Item>
                <ProductComponent
                  key={product.id}
                  product={product}
                  onDeleteProduct={onDeleteProduct(product.id)}
                  onEditProduct={onEditProduct(product.id)}
                />
              </List.Item>
            )}
          />
        )}
      </Space>
    </>
  );
};

export default ProductListPage;
