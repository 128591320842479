import React from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Card } from 'antd';

const { Meta } = Card;


const ProductComponent = ({ product, onDeleteProduct, onEditProduct }) => (
  <Card
    key={product.id}
    hoverable
    style={{ width: 240 }}
    cover={
      product.image && (
        <img 
          alt={product.brand.title} 
          src={product.image.url} 
          style={{ width: '100%', height: '15vw', objectFit: 'cover' }} 
        />
      )
    }
    actions={[
      <EditOutlined key="edit" onClick={onEditProduct} />,
      <DeleteOutlined key="delete" onClick={onDeleteProduct} />,
    ]}
  >
    <Meta title={product.brand.title} description={product.title} />
    <p>ID: {product.id}</p>
    <p>Category: {product.category.title}</p>
    <p>Size: {product.size}</p>
  </Card>
);

export default ProductComponent;
