import axios from 'axios';

export const createDetection = async (detection) => {
    const response = await axios.post('https://fsanchez.cl/detection', detection);
    return response.data;
};

export const getDetectionById = async (detectionId) => {
    const response = await axios.get(`https://fsanchez.cl/detection/${detectionId}`);
    return response.data;
}

export const downloadMetadata = async (metadataFileUrl) => {
    const response = await axios.get(metadataFileUrl);
    return response.data;
};

export const createShelfObjectDetection = async (detectionId, shelfObjectDetection) => {
    const response = await axios.post(`https://fsanchez.cl/detection/${detectionId}/shelf`, shelfObjectDetection);
    return response.data;
};

export const getShelvesDetectionsSummary = async () => {
    const response = await axios.get('https://fsanchez.cl/detection/summary');
    return response.data;
}
