import axios from 'axios';

export const getCategoriesList = async () => {
    const response = await axios.get('https://fsanchez.cl/categories');
    return response.data;
};

export const createCategory = async (category) => {
    const response = await axios.post('https://fsanchez.cl/categories', category);
    return response.data;
}
