import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PageLayout from './layouts/PageLayout';
import ProductListPage from './pages/ProductListPage';
import ShelfPage from './pages/ShelfPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PageLayout />}>
          <Route index element={<ProductListPage />} />
          <Route path="/products" element={<ProductListPage />} />
          <Route path="/shelfs" element={<ShelfPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
