import { Layout, Menu } from 'antd'
import { Link } from 'react-router-dom'

const { Header } = Layout

const logoStyle = {
    float: 'left',
    '&__img': {
        width: 110,
        height: 24,
    },
    '&__title': {
        textDecoration: 'none',
    },
};

const siteLayoutBackground = {
    background: '#fff',
};

const HeaderLayout = () => {
    const defaultSelectedKeys = ['products']

    const items = [
        {
            label: <Link to='/products'>Products</Link>,
            key: 'products'
        },
        {
            label: <Link to='/shelfs'>Shelf</Link>,
            key: 'shelfs'
        },
    ];

    return (
        <Header style={siteLayoutBackground}>
            <div style={logoStyle}>
                Owl Sight Products
            </div>
            <Menu
                theme='light'
                mode='horizontal'
                defaultSelectedKeys={defaultSelectedKeys}
                items={items}
            />
        </Header>
    )
}

export default HeaderLayout;
