import React, { useState, useEffect } from 'react';
import { Table, Tag } from 'antd';

import { getShelvesDetectionsSummary } from '../api';

const columns = [
    {
      title: 'Detection ID',
      dataIndex: 'detectionId',
      key: 'detectionId',
    },
    {
        title: 'Shelf ID',
        dataIndex: 'shelfId',
        key: 'shelfId',
    },
    {
        title: 'Detector Type',
        key: 'detectorType',
        dataIndex: 'detectorType',
        render: (_, { detectorType }) => <Tag color={'green'} key={detectorType}>{detectorType.toUpperCase()}</Tag>,
      },
    {
        title: 'Objects Detected',
        dataIndex: 'objectDetected',
        key: 'objectDetected',
    },
];

const ShelvesDetectionsTable = () => {
    const [shelvesDetections, setDetections] = useState([]);

    useEffect(() => {
        const fetchShelevesDetections = async () => {
            console.log('Loading shelves detections');
            let detections = await getShelvesDetectionsSummary();
            detections = detections.map((detection) => (
                {
                    detectionId: detection.detection_id,
                    shelfId: detection.shelf_id,
                    detectorType: detection.detector_type,
                    objectDetected: detection.object_detected,
                }
            ));
            setDetections(detections);
        };

        fetchShelevesDetections();
    }, []);

    return (
        <Table columns={columns} dataSource={shelvesDetections} />
    );
};

export default ShelvesDetectionsTable;
