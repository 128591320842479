import axios from 'axios';

export const getShelf = async (shelfId) => {
    const response = await axios.get(`https://fsanchez.cl/shelves/${shelfId}`);
    return response.data;
}

export const createShelf = async (shelf) => {
    const response = await axios.post('https://fsanchez.cl/shelves', shelf);
    return response.data;
}

export const updateShelf = async (shelfId, shelf) => {
    const response = await axios.put(`https://fsanchez.cl/shelves/${shelfId}`, shelf);
    return response.data;
}

export const deleteShelf = async (shelfId) => {
    const response = await axios.delete(`https://fsanchez.cl/shelves/${shelfId}`);
    return response.data;
}

export const getShelvesList = async () => {
    const response = await axios.get('https://fsanchez.cl/shelves');
    return response.data;
};
