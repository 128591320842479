import axios from 'axios';

export const getProduct = async (productId) => {
    const response = await axios.get(`https://fsanchez.cl/products/${productId}`);
    return response.data;
};

export const createProduct = async (product) => {
    const response = await axios.post('https://fsanchez.cl/products', product);
    return response.data;
};

export const updateProduct = async (productId, product) => {
    const response = await axios.put(`https://fsanchez.cl/products/${productId}`, product);
    return response.data;
};

export const deleteProduct = async (productId) => {
    const response = await axios.delete(`https://fsanchez.cl/products/${productId}`);
    return response.data;
};

export const getProductsList = async () => {
    const response = await axios.get('https://fsanchez.cl/products');
    return response.data;
};
