
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Layout } from 'antd';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HeaderLayout from './HeaderLayout';

const { Content } = Layout;

const contentStyle = {
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
  height: 'calc(100vh - 64px)',
};

const PageLayout = () => {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <ToastContainer />
            <HeaderLayout />
            <Content style={contentStyle}>
                <Outlet />
            </Content>
        </Layout>
    )
};
  
export default PageLayout;
